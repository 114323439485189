<template>
  <div class="page1">

<!--    <infoList :list="basicBaseList" :result="result">基本信息</infoList>-->

    <div class="title">证照信息</div>
    <el-button type="primary" size="mini" @click="dialogVisible=true">添加证照</el-button>


    <!--    证照信息form-->
    <div class="mt10">

      <div v-if="this.result.photoList.length>0">
        <div v-for="(item,index) in this.result.photoList" :key="index">
          <div v-if="item.tenantCardPhotoName=='营业执照'">
            <!-- 营业执照-->
            <div class="mb10 fwb">营业执照</div>
              <div class="mb20 flex flex-space-between">
                <div >证照{{index+1}}信息</div>
<!--                <el-button type="danger" size="small" @click="deleteArr(licenceList,index)">删除</el-button>-->
              </div>
              <EForm :formColumns="businessLicenseFormColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true"
                     :formData="item"
                     ref="form4" :searchFlag="false" :labelPosition="labelPosition" labelWidth="150px"
                     :formRules="formRules3"
                     :beginDate1="item.validBeginDate"
                     :endDate1="item.validEndDate"
                     :parentList="item.photoUrl"
                     @getImgList="getImgList1($event,index)"
                     @changeRadio="changeRadio1($event,index)"
                     @beginDateChange="beginDateChange1($event,index)"
                     @endDateChange="endDateChange1($event,index)"
              >
              </EForm>
          </div>
          <div v-else>
            <!--食品安全证-->
            <div class="mb10 fwb">食品安全许可证</div>
              <div class="mb20 flex flex-space-between">
                <div >证照{{index+1}}信息</div>
<!--                <el-button type="danger" size="small" @click="deleteArr(foodSafeLicenceList,index)">删除</el-button>-->
              </div>
              <EForm :formColumns="foodLicenseFormColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true"
                     :formData="item"
                     ref="form5" :searchFlag="false" :labelPosition="labelPosition" labelWidth="150px"
                     :formRules="formRules3"
                     :beginDate1="item.validBeginDate"
                     :endDate1="item.validEndDate"
                     @getImgList="getImgList1($event,index)">
              </EForm>
          </div>
        </div>
      </div>


      <div v-if="licenceList.length>0">
        <!-- 营业执照-->
        <div class="mb10 fwb">营业执照</div>
        <div v-for="(item,index) in licenceList" :key="index">
            <div class="mb20 flex flex-space-between">
                <div >证照{{index+1}}信息</div>
                <el-button type="danger" size="small" @click="deleteArr(licenceList,index)">删除</el-button>
            </div>
          <EForm :formColumns="businessLicenseFormColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true"
                 :formData="item"
                 ref="form2" :searchFlag="false" :labelPosition="labelPosition" labelWidth="150px"
                 :formRules="formRules3"
                 @getImgList="getImgList($event,index)"
                 @changeRadio="changeRadio($event,index)"
                 @beginDateChange="beginDateChange($event,index)"
                 @endDateChange="endDateChange($event,index)"
          >
          </EForm>
        </div>
      </div>

      <div v-if="foodSafeLicenceList.length>0">
        <!--食品安全证-->
        <div class="mb10 fwb">食品安全许可证</div>
        <div v-for="(item,index) in foodSafeLicenceList" :key="index">
            <div class="mb20 flex flex-space-between">
                <div >证照{{index+1}}信息</div>
                <el-button type="danger" size="small" @click="deleteArr(foodSafeLicenceList,index)">删除</el-button>
            </div>
          <EForm :formColumns="foodLicenseFormColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true"
                 :formData="item"
                 ref="form3" :searchFlag="false" :labelPosition="labelPosition" labelWidth="150px"
                 :formRules="formRules3"
                 @getImgList="getImgList2($event,index)">
          </EForm>
        </div>
      </div>

    </div>
    <el-row class="mt20" v-if="licenceList.length>0||foodSafeLicenceList.length>0||result.photoList.length>0">
      <el-button type="primary" @click="saveData" size="small" :disabled="saveDisabled">保存</el-button>
      <el-button type="primary" @click="goBack" size="small">取消</el-button>
    </el-row>

    <!-- 选择证照名称弹窗 -->
    <EDialog :dialogVisible="dialogVisible" title="选择证照" @handleClose="cancelDialog" width="30%"
             @handleClick="onSummit(dialogForm.tenantCardPhotoType)">
      <EForm :formColumns="dialogFormColumns"
             :rowSize="1" :optionsBtn="false"
             :actionBtn="false"
             :formData="dialogForm"
             ref="dialogForm" :searchFlag="false"
             :labelPosition="labelPosition"
             :labelWidth="labelWidth"
             :formRules="formRules2">
      </EForm>
    </EDialog>
<!--      <div v-for="item in result.photoList">-->
<!--          <infoList :list="photoBaseList" :result="item" v-if="item.tenantCardPhotoName=='营业执照'"></infoList>-->
<!--          <infoList :list="photoBaseList2" :result="item" v-else></infoList>-->
<!--      </div>-->
  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import infoList from '@/components/infoList'
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'shopLicenceAdd',
    mixins: [otherMixin],
    data() {
      return {
        id: this.$route.query.id,
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formColumns: [
          {
            title: '商户名称',
            property: 'tenantName',
            type: 'input',
            placeholder: '商户名称',
            show: true
          },
          {
            title: '联系电话',
            property: 'phone',
            type: 'input',
            placeholder: '例如：0571-88888888',
            show: true
          },
          {
            title: '负责人姓名',
            property: 'signPerson',
            type: 'input',
            placeholder: '真实姓名',
            show: true
          },
          {
            title: '经营范围',
            property: 'businessScopeId',
            options: [],
            type: 'select',
            show: true
          },
          {
            title: '身份证号码',
            property: 'idCard',
            type: 'input',
            placeholder: '例如：286057188888888',
            show: true
          },
          {
            title: '商户类型',
            property: 'tenantType',
            type: 'select',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1
              },
              {
                label: '公司',
                value: 2
              },
              {
                label: '其他',
                value: 3
              },
            ]
          },
          {
            title: '负责人籍贯',
            property: 'birthPlace',
            type: 'input',
            placeholder: '籍贯',
            show: true
          },
          {
            title: '身份证照片',
            property: 'idCardPath',
            type: 'mixinUpload',
            show: true
          },
        ],
        dialogFormColumns: [
          {
            title: '证照名称',
            type: 'select',
            property: 'tenantCardPhotoType',
            placeholder: '',
            show: true,
            options: [
              {
                label: '营业执照',
                value: 1
              },
              {
                label: '食品经营许可证',
                value: 2
              }
            ]
          },
        ],
        //Business license营业执照
        businessLicenseFormColumns: [
          {
            title: '名称',
            type: 'input',
            property: 'operatorName',
            placeholder: '',
            show: true,
          },
          {
            title: '证照名称',
            type: 'select',
            property: 'tenantCardPhotoType',
            placeholder: '',
            show: true,
            disabled: true,
            options: [
              {
                label: '营业执照',
                value: 1
              },
              {
                label: '食品经营许可证',
                value: 2
              }
            ]
          },
          {
            title: '统一社会信用代码',
            type: 'input',
            property: 'idCard',
            placeholder: '',
            show: true,
          },
          {
            title: '住所',
            type: 'input',
            property: 'address',
            placeholder: '',
            show: true,
          },
          {
            title: ' 类别',
            type: 'radio',
            property: 'ttype',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1
              },
              {
                label: '公司',
                value: 2
              },
            ]
          },
          {
            title: '状态',
            type: 'radio',
            property: 'status',
            placeholder: '',
            show: true,
            options: [
              {
                label: '设立中',
                value: 1
              },
              {
                label: '变更中',
                value: 2
              },
              {
                label: '注销中',
                value: 3
              },
              {
                label: '办理完成',
                value: 4
              },
              {
                label: '注销完成',
                value: 6
              },
            ]
          },
          {
            title: '法人姓名',
            type: 'input',
            property: 'corporationPerson',
            placeholder: '',
            show: true,
          },
          {
            title: ' 经营项目',
            type: 'input',
            property: 'businessScope',
            // options: [],
            placeholder: '',
            show: true,
          },
          {
            title: '签发日期',
            type: 'datePicker',
            property: 'issuerDate',
            show: true
          },
          {
            title: '注册资本',
            type: 'input',
            property: 'registeredCapital',
            placeholder: '',
            show: true,
          },
          {
            title: '经营期限',
            type: 'periods',
            property: 'time',
            placeholder: '',
            show: true,
          },
          {
            title: '成立日期',
            type: 'datePicker',
            property: 'registerDate',
            show: true
          },
          {
            title: '照片',
            type: 'mixinUpload',
            property: 'photoUrl',
            placeholder: '',
            show: true,
          },
          {
            title:'备注',
            type:'textarea',
            property:'description',
            placeholder:'最多可录入500字',
            show:true
          }
        ],

        // Food safety license 食品安全许可证
        foodLicenseFormColumns: [
          {
            title: '经营者名称',
            type: 'input',
            property: 'operatorName',
            placeholder: '',
            show: true,
          },
          {
            title: '证照名称',
            type: 'select',
            property: 'tenantCardPhotoType',
            placeholder: '',
            show: true,
            disabled: true,
            options: [
              {
                label: '营业执照',
                value: 1
              },
              {
                label: '食品经营许可证',
                value: 2
              }
            ]
          },
          {
            title: '统一社会信用代码（身份证号码）',
            type: 'input',
            property: 'idCard',
            placeholder: '',
            show: true,
          },
          {
            title: '状态',
            type: 'radio',
            property: 'status',
            placeholder: '',
            show: true,
            options: [
              {
                label: '设立中',
                value: 1
              },
              {
                label: '变更中',
                value: 2
              },
              {
                label: '注销中',
                value: 3
              },
              {
                label: '办理完成',
                value: 4
              },
              {
                label: '注销完成',
                value: 6
              },
            ]
          },
          {
            title: '许可证编号',
            type: 'input',
            property: 'tenantCardPhotoCode',
            placeholder: '',
            show: true,
          },
          {
            title: '住所',
            type: 'input',
            property: 'address',
            placeholder: '',
            show: true,
          },
          {
            title: '日常监督管理机构',
            type: 'input',
            property: 'superviseOrganization',
            placeholder: '',
            show: true,
          },
          {
            title: '经营场所',
            type: 'input',
            property: 'premises',
            placeholder: '',
            show: true,
          },
          {
            title: '日常监督管理人员',
            type: 'input',
            property: 'supervisePerson',
            placeholder: '',
            show: true,
          },
          {
            title: '主体业态',
            type: 'input',
            property: 'mainWork',
            placeholder: '',
            show: true,
          },
          {
            title: '投诉举报电话',
            type: 'input',
            property: 'lineTelephone',
            placeholder: '',
            show: true,
          },
          {
            title: ' 经营范围',
            type: 'input',
            property: 'businessScope',
            // options: [],
            placeholder: '',
            show: true,
          },
          {
            title: '发证机关',
            type: 'input',
            property: 'registerOffice',
            placeholder: '',
            show: true,
          },
          {
            title: '签发日期',
            type: 'datePicker',
            property: 'issuerDate',
            show: true
          },
          {
            title: '签发人',
            type: 'input',
            property: 'issuerPerson',
            placeholder: '',
            show: true,
          },
          {
            title: '有效期至',
            type: 'datePicker',
            property: 'validEndDate',
            show: true
          },
          {
            title: '  照片',
            type: 'mixinUpload',
            property: 'photoUrl',
            placeholder: '',
            show: true,
          },
          {
            title:'备注',
            type:'textarea',
            property:'description',
            placeholder:'最多可录入500字',
            show:true
          }
        ],
        licenceList: [],
        licenceListHis: [],
        foodSafeLicenceList: [],
        foodSafeLicenceListHis: [],
        licenceListAll: [],//传给后台的两个数组合并成一个
        basicBaseList: [
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '录入人',
            property: 'createUserCn',
          },
          {
            label: '录入时间',
            property: 'createTime',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type: 'img'
          },
        ],
        //证照经营
        photoBaseList: [
          {
            label: '名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码',
            property: 'idCard',
          },
          {
            label: '住所',
            property: 'address',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '类别',
            property: 'ttypeCn',
          },
          {
            label: '登记机关',
            property: 'registerOffice',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '注册资本',
            property: 'registeredCapital',
          },
          {
            label: '经营期限',
            property: 'validDateStr',
          },
          {
            label: '成立日期',
            property: 'registerDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type:'imgList'
          },
        ],
        //食品证照
        photoBaseList2: [
          {
            label: '经营者名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码（身份证号码）',
            property: 'idCard',
          },
          {
            label: '许可证编号',
            property: 'tenantCardPhotoCode',
          },
          {
            label: '住所',
            property: 'address',
          },
          {
            label: '日常监督管理机构',
            property: 'superviseOrganization',
          },
          {
            label: '经营场所',
            property: 'premises',
          },
          {
            label: '日常监督管理人员',
            property: 'supervisePerson',
          },
          {
            label: '主体业态',
            property: 'mainWork',
          },
          {
            label: '投诉举报电话',
            property: 'lineTelephone',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '发证机关',
            property: 'registerOffice',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '签发人',
            property: 'issuerPerson',
          },
          {
            label: '有效期至',
            property: 'validEndDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type:'imgList'
          },
        ],
        result: {
          tenantName: '',
          idCard: '',
          tenantTypeCn: '',
          signPerson: '',
          phone: '',
          birthPlace: '',
          createUserCn: '',
          createTime: '',
          updateUserCn: '',
          updateTime: '',
          tenantCardPhotoType: '',
        },
        form: {
          tenantName: '',
          phone: '',
          signPerson: '',
          businessScopeId: '',
          idCard: '',
          tenantType: '',
          birthPlace: '',
          idCardPath: '',
        },
        // 营业执照
        businessInfoForm: {
          tenantBasicInformationId: '',
          tenantCardPhotoCode: '',
          businessScopeId: '',
          operatorName: '',
          address: '',
          registerOffice: '',
          registerDate: '',
          registeredCapital: '',
          tenantCardPhotoType: '',
          corporationPerson: '',
          idCard: '',
          ttype: '',
          description: '',
          status: '1',
          photoUrl: '',
          validDateType: 1,
          validBeginDate: '',
          validEndDate: '',
          businessScope:'',
          boothCode:'',
          contractId:''

        },
        // 食品安全证
        foodSafeInfoForm: {
          tenantBasicInformationId: '',
          tenantCardPhotoCode: '',
          businessScopeId: '',
          operatorName: '',
          address: '',
          superviseOrganization: '',
          supervisePerson: '',
          premises: '',
          mainWork: '',
          lineTelephone: '',
          registerOffice: '',
          issuerPerson: '',
          issuerDate: '',
          validEndDate: '',
          tenantCardPhotoType: '',
          corporationPerson: '',
          idCard: '',
          ttype: '',
          description: '',
          status: '1',
          photoUrl: '',
          businessScope:'',
          boothCode:'',
          contractId:''
        },
        dialogForm: {
          tenantCardPhotoType: ''
        },
        formRules: {
          tenantName: vxRule(true, '', "change", "商户名称不能为空"),
          phone: vxRule(true, '', "blur", "联系电话不能为空"),
          signPerson: vxRule(true, '', "blur", "负责人姓名不能为空"),
          idCard: [vxRule(true, '', "blur", "统一社会信用代码不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==18, errMsg: "请输入18位统一社会信用代码"}})],
          businessScopeId: vxRule(true, '', "blur", "经营范围不能为空"),
        },
        formRules2: {
          tenantCardPhotoType: vxRule(true, '', "change", "证照名称不能为空"),
        },
        formRules3: {
          operatorName: vxRule(true, '', "change", "经营者名称不能为空"),
          ttype:vxRule(true, '', "change", "经营类别不能为空"),
          idCard: [vxRule(true, '', "change", "统一社会信用代码不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==18, errMsg: "请输入18位统一社会信用代码"}})],
        },
        tenantBasicInformationId: '',//商户信息id
      }
    },
    watch: {},
    created() {
      console.log(this.$route.query.boothCode)
      this.businessInfoForm.boothCode = this.$route.query.boothCode
      this.businessInfoForm.contractId = this.$route.query.contractId
      // this.businessInfoForm.tenantBasicInformationId = this.$route.query.id
      // this.foodSafeInfoForm.tenantBasicInformationId = this.$route.query.id
      this.foodSafeInfoForm.boothCode = this.$route.query.boothCode
      this.foodSafeInfoForm.contractId = this.$route.query.contractId
      this.boothCode=this.$route.query.boothCode
      this.contractId=this.$route.query.contractId

      this.cardbusinessscopeLabel()
      this.contractDetail()
    },
    components: {ETable, EButton, EForm, infoList, EDialog},
    beforeMount() {

    },
    methods: {
      deleteArr(arr,i){
        arr.splice(i,1)
      },
      getImgList(data,index) {
        if (index || index == '0') {
          this.licenceList[index].photoUrl = data
        }
      },
      getImgList1(data,index) {
        if (index || index == '0') {
          this.result.photoList[index].photoUrl = data
        }
      },
      getImgList2(data, index) {
        if (index || index == '0') {
          this.foodSafeLicenceList[index].photoUrl =data
        }
      },
      changeRadio(val, index) {
        this.licenceList[index].validDateType = val
      },
      changeRadio1(val, index) {
        this.result.photoList[index].validDateType = val
      },
      beginDateChange(val, index) {
        console.log(val)
        this.licenceList[index].validBeginDate = val
        console.log(this.licenceList)
      },
      beginDateChange1(val, index) {
        this.result.photoList[index].validBeginDate = val
      },
      endDateChange(val, index) {
        this.licenceList[index].validEndDate = val
      },
      endDateChange1(val, index) {
        this.result.photoList[index].validEndDate = val
      },
      addLicence() {
        this.licenceList.push({
          baseList: [
            {
              label: '商户名称',
              property: 'title',
              type: 'input'
            },
            {
              label: '联系电话',
              property: 'mobile',
              type: 'input'
            },
          ],
          result: {
            title: '',
            mobile: ''
          }
        })
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          tenantCardPhotoType: '',
        }
        this.$refs.dialogForm.$refs.form.resetFields()
      },
      onSummit(tenantCardPhotoType) {
        this.$refs['dialogForm'].$refs['form'].validate((valid, object) => {
          if (valid) {
            this.dialogVisible = false
            if (tenantCardPhotoType == 1) {
              // 营业执照
              this.licenceList.push({
                boothCode:this.businessInfoForm.boothCode,
                contractId:this.businessInfoForm.contractId,
                businessScopeId: this.businessInfoForm.businessScopeId,
                corporationPerson: this.businessInfoForm.corporationPerson,
                description: this.businessInfoForm.description,
                operatorName: this.businessInfoForm.operatorName,
                address: this.businessInfoForm.address,
                idCard: this.businessInfoForm.idCard,
                photoUrl: this.businessInfoForm.photoUrl,
                status: this.businessInfoForm.status,
                tenantBasicInformationId: this.businessInfoForm.tenantBasicInformationId,
                tenantCardPhotoCode: this.businessInfoForm.tenantCardPhotoCode,
                tenantCardPhotoType: tenantCardPhotoType,
                ttype: this.businessInfoForm.ttype,
                registerDate: this.businessInfoForm.registerDate,
                registerOffice: this.businessInfoForm.registerOffice,
                registeredCapital: this.businessInfoForm.registeredCapital,
                validDateType: this.businessInfoForm.validDateType,
                validBeginDate: this.businessInfoForm.validBeginDate,
                validEndDate: this.businessInfoForm.validEndDate,
              })
            } else {
              // 食品安全许可证
              this.foodSafeLicenceList.push({
                boothCode:this.foodSafeInfoForm.boothCode,
                contractId:this.foodSafeInfoForm.contractId,
                businessScopeId: this.foodSafeInfoForm.businessScopeId,
                corporationPerson: this.foodSafeInfoForm.corporationPerson,
                description: this.foodSafeInfoForm.description,
                operatorName: this.businessInfoForm.operatorName,
                address: this.businessInfoForm.address,
                idCard: this.foodSafeInfoForm.idCard,
                photoUrl: this.foodSafeInfoForm.photoUrl,
                status: this.foodSafeInfoForm.status,
                superviseOrganization: this.foodSafeInfoForm.superviseOrganization,
                supervisePerson: this.foodSafeInfoForm.supervisePerson,
                premises: this.foodSafeInfoForm.premises,
                mainWork: this.foodSafeInfoForm.mainWork,
                lineTelephone: this.foodSafeInfoForm.lineTelephone,
                registerOffice: this.foodSafeInfoForm.registerOffice,
                issuerDate: this.foodSafeInfoForm.issuerDate,
                issuerPerson: this.foodSafeInfoForm.issuerPerson,
                tenantBasicInformationId: this.foodSafeInfoForm.tenantBasicInformationId,
                tenantCardPhotoCode: this.foodSafeInfoForm.tenantCardPhotoCode,
                tenantCardPhotoType: tenantCardPhotoType,
                ttype: this.foodSafeInfoForm.ttype,
                validEndDate: this.foodSafeInfoForm.validEndDate,
              })
            }
            this.cancelDialog()
          } else {
            return false;
          }
        })
      },
      saveData() {
        console.log('添加证照')
        let flag1 = false
        if(this.licenceList.length>0){
          for (let i = 0; i < this.licenceList.length; i++) {
            this.$refs['form2'][i].$refs['form'].validate((valid) => {
              if (valid) {
                flag1 = true
              } else {
                return false;
              }
            });
          }
        }else{
          flag1=true
        }

        let flag2 = false
        if(this.foodSafeLicenceList.length>0){
          for (let i = 0; i < this.foodSafeLicenceList.length; i++) {
            this.$refs['form3'][i].$refs['form'].validate((valid) => {
              if (valid) {
                flag2 = true
              } else {
                return false;
              }
            });
          }
        }else{
          flag2=true
        }

        // let flag3 = false
        // let flag4 = false
        // if(this.result.photoList.length>0){
        //   for (let i = 0; i < this.result.photoList.length; i++) {
        //     console.log()
        //     if(this.result.photoList[i].tenantCardPhotoName=='营业执照'){
        //       this.$refs['form4'][i].$refs['form'].validate((valid) => {
        //         if (valid) {
        //           flag3 = true
        //           if(this.result.photoList.length==1){
        //             flag4 = true
        //           }
        //         }
        //       });
        //     }else{
        //       this.$refs['form5'][i].$refs['form'].validate((valid) => {
        //         if (valid) {
        //           flag4 = true
        //           if(this.result.photoList.length==1){
        //             flag3 = true
        //           }
        //         }
        //       });
        //     }
        //
        //   }
        // }else{
        //   flag3=true
        //   flag4=true
        // }

        if (flag1 && flag2) {
          this.getPhotoSubmit()
        }
      },
      //新增证照
      async getPhotoSubmit() {

        this.setDisabled(true)
        //将两个证照数组push进一个数组传给后台
        this.licenceList.forEach(item => {
          if (item.validDateType == 2) {
            item.validEndDate = ''
          }
          // item.photoUrl=item.photoUrl.toString()
          this.licenceListAll.push(item)
        })
        this.foodSafeLicenceList.forEach(item => {
          // item.photoUrl=item.photoUrl.toString()
          this.licenceListAll.push(item)
        })
        if(this.result.photoList.length>0){
          this.result.photoList.forEach(item => {
            this.licenceListAll.push(item)
          })
        }
        let licenceListAll=JSON.parse(JSON.stringify(this.licenceListAll))
        licenceListAll.forEach(item => {
          item.photoUrl=item.photoUrl.toString()
        })
        let res = await Http.cmtenantcardphotoSaveList(licenceListAll)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.goBack()
          this.setDisabled(false)
        }
      },
      //证照经营范围下拉
      async cardbusinessscopeLabel() {
        let res = await Http.cardbusinessscopeLabel({
          status: 1
        })
        if (res.code == 200) {
          this.businessLicenseFormColumns[6].options = res.data
          this.foodLicenseFormColumns[10].options = res.data
        }
      },
      //获取商户证照详情
      async contractDetail() {
        let res = await Http.contractDetail({id: this.$route.query.contractId})
        if (res.code == 200) {
          this.result = res.data
          this.result.photoList.forEach(item=>{
            item.photoUrl=item.photoUrl.split(',')
            item.status=Number(item.status)
            item.ttype=Number(item.ttype)
          })
        }
      },
      goTo(url) {
        this.$router.push({name: url})
      },
    }
  }
</script>

<style lang="scss">
  .title {
    font-weight: bold;
    padding-left: 10px;
    border-left: 2px solid #4d68ff;
    margin-bottom: 20px;
  }

  .small-title {
    margin-left: 10px;
    margin-top: 20px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
</style>
